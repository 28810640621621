const createPopup = function() {

	function Popup(position, template, type) {
		this.popupType = type;
		this.position = new window.google.maps.LatLng(position.lat, position.lng);
		this.containerDiv = document.createElement('div');
		this.containerDiv.className = "c-search-map__fullscreen-wrapper";
		this.containerDiv.innerHTML = template;
		const close = this.containerDiv.querySelector('.js-overlay-close');

		if (close) {
			$(close).click(function() {
				window.site.body.trigger({
					type: 'closeMapOverlay'
				});
			});
		}

		window.google.maps.OverlayView.preventMapHitsAndGesturesFrom(this.containerDiv);
	}

	Popup.prototype = Object.create(window.google.maps.OverlayView.prototype);

	Popup.prototype.onAdd = function() {
		this.getPanes().floatPane.appendChild(this.containerDiv);
	};

	Popup.prototype.onRemove = function() {
		if (this.containerDiv.parentElement) {
			this.containerDiv.parentElement.removeChild(this.containerDiv);
		}
	};

	Popup.prototype.draw = function() {
		const divPosition = this.getProjection().fromLatLngToDivPixel(this.position);

		// Hide the popup when it is far out of view.
		const display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? 'block' : 'none';

		if (display === 'block') {
			this.containerDiv.style.left = divPosition.x + 'px';
			this.containerDiv.style.top = divPosition.y + 'px';
		}
		if (this.containerDiv.style.display !== display) {
			this.containerDiv.style.display = display;
		}
	};

	Popup.prototype.panToOverlayCenter = function(markerLatLng, map) {
		markerLatLng = new window.google.maps.LatLng(markerLatLng.lat, markerLatLng.lng);
		const overlayInfo = this.containerDiv.getBoundingClientRect();

		const width = (overlayInfo.width - (overlayInfo.width / 10)) / 2;
		const height = (overlayInfo.height / 2) - 12;

		const location = offset(markerLatLng, -width, height);

		map.panTo(location);

		function offset(markerPosition, offsetx, offsety) {

			const scale = Math.pow(2, map.zoom);
			const worldCoordinateCenter = map.getProjection().fromLatLngToPoint(markerPosition);
			const pixelOffset = new window.google.maps.Point((offsetx / scale) || 0, (offsety / scale) || 0);
			const worldCoordinateNewCenter = new window.google.maps.Point(
				worldCoordinateCenter.x - pixelOffset.x,
				worldCoordinateCenter.y + pixelOffset.y
			);

			return map.getProjection().fromPointToLatLng(worldCoordinateNewCenter);
		};
	};

	return Popup;
};


export default createPopup 
